
function GetVideoEmbedUrl(url, options) {
	if ( typeof(url) === 'undefined' || typeof(url) !== 'string' ) {
		return;
	}
	
	var defaults = {
		parameters: {
			autoplay: 0,
			vimeoTitle: 0,
			vimeoByline: 0,
			vimeoPortrait: 0
		}
	};
	
	var options = $.extend( true, {}, defaults, options );
	var embedUrl = getVideoUrl(url, options);
	
	if ( embedUrl !== 'undefined' ) {
		return embedUrl;
	}
	
	function getVideoUrl(url, opts) {
		var youtube = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
		var vimeo = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/;
		var yt = url.match(youtube);
		var vm = url.match(vimeo);
		var embedUrl;
	
		if ( yt ) {
			embedUrl = "//www.youtube.com/embed/" + yt[1];
			embedUrl +=  "?autoplay=" + opts.parameters.autoplay;
		} else if ( vm ) {
			embedUrl = "//player.vimeo.com/video/" + vm[4];
			embedUrl +=  "?autoplay=" + opts.parameters.autoplay;
			embedUrl +=  "&title=" + opts.parameters.vimeoTitle;
			embedUrl +=  "&byline=" + opts.parameters.vimeoByline;
			embedUrl +=  "&portrait=" + opts.parameters.vimeoPortrait;
		}

		if (yt || vm) {
			return embedUrl;
		} else {
			return false;
		}
	}
};