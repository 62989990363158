function navigation() {
	var $menuToggle = $('.js-menu-toggle');
	var $html = $('html');
	var $navLinks = $('.js-main-nav .link');
	var timeouts = [];
	var $window = $(window);
	var props = {
		enableFixedHeader: false,
		lastKnownScrollY: $window.scrollTop(),
		headerHeight: $('.site-header').height(),
		scrollBuffer: 100,
		headerReduced: false
	};
	
	$('.js-menu-toggle').on('click', function() {
		if ( $html.hasClass('state-menu-visible') ) {
			$html.removeClass('state-menu-visible');
			$navLinks.removeClass('-visible');
			for ( var i=0; i < timeouts.length; i++ ) {
				clearTimeout(timeouts[i]);
			}
		} else {
			$html.addClass('state-menu-visible');
			showNavItems($navLinks);
		}
	});
	
	function showNavItems($links) {
		for ( var i=0; i < $links.length; i++ ) {
			var $link = $links.eq(i);
			var delay = 100 + ((i + 1) * 100) + (50 / (i + 1));
			
			animateIn($link);
			
			function animateIn($link) {
				timeouts.push(setTimeout(function() {
					$link.addClass('-visible');
				}, delay));
			}
		}
	}
	
	function onScroll() {
		var context = MQ.getContext();
		
		// If browser size is large enough (ie. not showing mobile nav) then check
		// scroll position and add class to HTML tag to control resizing of header
		if ( context == 'large' || context == 'xlarge' ) {
			props.lastKnownScrollY = $window.scrollTop();
			
			// Reduce height of header & fade in small logo once scrolled past hero image
			if ( props.lastKnownScrollY > props.headerHeight + props.scrollBuffer ) {
				if ( !props.headerReduced ) {
					$('html').addClass('fixed-header-small');
					props.headerReduced = true;
				}
			} else {
				if ( props.headerReduced ) {
					$('html').removeClass('fixed-header-small');
					props.headerReduced = false;
				}
			}

			requestAnimationFrame( onScroll );
		} else {
			resetScrolling();
		}
	}
	
	function resetScrolling() {
		// Undo resized header settings if not required
		if ( props.headerReduced ) {
			$('html').removeClass('fixed-header-small');
			props.headerReduced = false;
		}
	}
	
	// Set up scroll events for fixed header resizing on scroll.
	onScroll();
	$(window).resize( $.throttle( 250, onScroll ) );
}