$(function() {
	$('body').removeClass('animate-in');
	
	// Set up responsive hooks
	var queries = [
		{
			context: 'small',
			match: function() { }
		},
		{
			context: 'medium',
			match: function() { }
		},
		{
			context: 'large',
			match: function() { }
		},
		{
			context: 'xlarge',
			match: function() { }
		}
	];
	MQ.init(queries);
	
	navigation(); // Requires navigation.js
	
	$('.js-condense').each(function() {
		// Requires jquery.condense.js
		$(this).condense({
			condensedLength: 240,
			moreText: "&hellip;more",
			lessText: "",
			ellipsis: "",
			moreSpeed: 0,  
			lessSpeed: 0
		});
	});
	
	$('.js-map').each(function() {
		var $map = $(this);
		var mapLat = $map.attr('data-latitude');
		var mapLng = $map.attr('data-longitude');
		var id = $map.attr('id');
		
		if ( id && mapLat && mapLng ) {
			var map = new GMaps({
				el: '#' + id,
				lat: mapLat,
				lng: mapLng
			});
			
			map.addMarker({
				lat: mapLat,
				lng: mapLng,
				title: 'Images & Sound'
			});
		}
	});
	
	$('.js-filters').each(function() {
		// Requires isotope-filter.js
		var filter = new makeFilterable( $(this), $('.js-filter-items'), $(this).attr('data-layout') );
	});
	
	$('.js-artist-filters').each(function() {
		// Requires artist-filters.js
		var filter = new artistFilters( $(this), $('.js-artist-item') );
	});
	
	$('.js-fitvids').fitVids(); // Requires jquery.fitvids.js
	
	$('.js-showreels').each(function() {
		// Requires showreels.js
		var showreels = new Showreels( $(this), { width: 890, height: 500 } );
	});
	
	if ( MQ.getContext() == 'large' || MQ.getContext() == 'xlarge' ) {
		addMesh(); // Defined in /vendors/mesh-background/animate.js
	} else {
		$('#fss-output').append('<div class="mesh-fallback"></div>');
	}

	$(window).on('resize', function() {
		// If there are any expanded js-condense elements we need to reset their width
		$('.js-condense.-expanded').each(function() {
			$(this).css({
				width: 'auto',
				height: 'auto'
			});
		});
	});
});