/*
	makeFilterable
	
	Sets up Isotope filtering for a collection of items.
	Note: This assumes there will only be one filterable set on the page at a time.
*/

var makeFilterable = function($filters, $items, layout) {
	this.$filters = $filters;
	this.$items = $items;
	this.layout = layout ? layout : 'fitRows';
	this.urlParams = {};
	
	if ( !this.$filters || !this.$items ) {
		return;
	}
	
	this._init();
};

makeFilterable.prototype = {
	_init: function() {
		var me = this;
		
		me.$filterItems = me.$filters.find('[data-filter]');
		
		me.filter = me.$items.isotope({
			itemSelector: '.js-filter-item',
		  	layoutMode: me.layout
		});
		
		me.$filterItems.on( 'click', function(e) {
			e.preventDefault();
			var filterValue = $( this ).attr('data-filter');
		  	me.filter.isotope({ filter: filterValue });
			me.update(this);
		});
		
		// If a category was passed in via a querystring parameter then change initial filter
		me.getParams();

		if ( me.urlParams['category'] ) {
			me.$filterItems.filter('[data-filter=".' + me.urlParams['category'] + '"]').trigger('click');
		}
	},
	
	update: function(el) {
		var me = this;
		
		me.$filterItems.removeClass('-selected');
		$(el).addClass('-selected');
	},
	
	getParams: function() {
		var me = this;
		
		var match,
			pl     = /\+/g,  // Regex for replacing addition symbol with a space
			search = /([^&=]+)=?([^&]*)/g,
			decode = function (s) {
				return decodeURIComponent(s.replace(pl, " "));
			},
			query  = window.location.search.substring(1);

		while ( match = search.exec(query) ) {
			me.urlParams[decode(match[1])] = decode(match[2]);
		}
	}
};