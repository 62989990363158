var Showreels = function($showreels, options) {
	if ( typeof($showreels) === 'undefined' ) {
		return;
	}
	
	if ( typeof($showreels) === 'object' ) {
		this.$showreels = $showreels;
		this.$links = $showreels.find('.js-showreel-link');
		this.$embedContainer = $showreels.find('.js-showreel-video');
		this.options = $.extend( true, {}, this.defaults, options );
		
		if ( this.$links.length && this.$embedContainer.length ) {
			this._init();
		} else {
			return;
		}
	}
};

Showreels.prototype = {
	defaults: {
		width: 960,
		height: 540
	},
	
	_init: function() {
		var me = this;
		
		me.$links.on('click.showreel', function(e) {
			e.preventDefault();
			
			var $link = $(this);
			var url = $link.attr('data-videourl');
			var embedUrl = GetVideoEmbedUrl( url, { parameters: { autoplay: 1 } } );
			
			if ( embedUrl ) {
				me.insertVideo( embedUrl );
				me.updateActiveLink( $link );
			}
		});
	},
	
	insertVideo: function(url) {
		var me = this;
		
		var embed = '<iframe src="' + url + '" width="' + me.options.width + '" height="' + me.options.height + '" frameborder="0"></iframe>';
		
		this.$embedContainer.html(embed);
	},
	
	updateActiveLink: function($link) {
		var me = this;
		
		me.$links.removeClass('-selected');
		$link.addClass('-selected');
	}
};