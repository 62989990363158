/*
	artistFilters

	Custom filtering for the Artists section.
	Assumes that only one set of filters exists on the page.
	Requires the HTML markup using classes/attributes as follows:

	<ul class="js-artist-filters">
		<li><a class="-selected" href data-filter="*">All</a></li>
		<li><a href data-filter="id-1">One</a></li>
		<li><a href data-filter="id-2">Two</a></li>
		<li><a href data-filter="id-3">Three</a></li>
	</ul>

	<section class="js-artist-item id-1">One</section>
	<section class="js-artist-item id-2">Two</section>
	<section class="js-artist-item id-3">Three</section>
	<section class="js-artist-item id-1 id-3">Multiple</section>
*/

var artistFilters = function($filters, $items) {
	this.$filters = $filters;
	this.$items = $items;
	
	if ( !this.$filters || !this.$items ) {
		return;
	}
	
	this._init();
};

artistFilters.prototype = {
	_init: function() {
		var me = this;
		
		me.$filterItems = me.$filters.find('[data-filter]');
		
		me.currentFilter = me.$filterItems.filter('.-selected').attr('[data-filter]');
		
		me.$filterItems.on( 'click', function(e) {
			e.preventDefault();
			var filterValue = $( this ).attr('data-filter');
			
			if ( filterValue !== me.currentFilter ) {
			  	me.filterItems(filterValue);
				me.updateState(this);
			}
		});
	},
	
	filterItems: function(filter) {
		var me = this;
		
		me.currentFilter = filter;

		if ( filter === '*' ) {
			// Show all
			me.$items.fadeIn(200);
		} else {
			// Hide all items
			me.$items.stop().fadeOut(200);
		
			// Show filtered items
			setTimeout(function() {
				// If any artist bios have been expanded, collapse them first.
				me.$items.find('.js-condense.-expanded .condense_control_less').each(function() {
					$(this).trigger('click');
				});
				
				me.$items.filter('.' + filter).fadeIn(200);
			}, 300);
		}
	},
	
	updateState: function(el) {
		var me = this;
		
		// Update selected state of filter nav
		me.$filterItems.removeClass('-selected');
		$(el).addClass('-selected');
	}
};